<template>
  <div class="fans">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="onLoad"
    >
      <div class="list">
        <fans-list
          :listFans="listFans"
          :isSelection="isSelection"
          @getFan="actionDidTap"
        ></fans-list>
      </div>
    </van-list>
  </div>
</template>
<script>
import Vue from "vue";

import { List } from "vant";
Vue.use(List);

import FansList from "./components/fansList";

export default {
  name: "Fans",
  props: {
    isSelection: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FansList,
  },
  data() {
    return {
      listFans: [],
      pageIndex: 1,
      pageSize: 10,
      loading: true,
      finished: false,
    };
  },
  mounted() {
    //首次加载数据
    this.getShopFansListByPage();
  },
  methods: {
    actionDidTap(item) {
      this.$emit("actionDidTap", item);
    },
    // 首次加载数据
    getShopFansListByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        PageIndex: 1,
        PageSize: this.pageSize,
      };
      this.$api.shop
        .getShopFansListByPage(param)
        .then((res) => {
          const data = res.data;
          this.listFans = data;
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    //加载更多
    onLoad() {
      let param = { PageIndex: this.pageIndex + 1, PageSize: this.pageSize };
      this.$api.shop
        .getShopFansListByPage(param)
        .then((res) => {
          const data = res.data;
          if (data === "" || data === null || data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.listFans = this.listFans.concat(data);
            this.pageIndex = this.pageIndex + 1;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.fans {
  width: 100%;
  font-size: 26px;
  .list {
    width: 100%;
  }
  .tabList {
    width: 100%;
    padding-top: 38px;
    text-align: center;
    background-color: #ffffff;
    .tabItem {
      width: 33.33%;
      text-align: center;
      font-size: 26px;
      color: #333333;
      .tabItemTotal {
        margin: 0;
        padding: 0;
      }
      .itemTop {
        @include puiblicFlex;
        text-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        .itemTopName {
          margin: 0;
          padding: 0;
        }
        .itemTopNum {
          margin: 0;
          padding: 0;
        }
      }
      .itemTopAction {
        color: #43cd9a;
      }
      .itemBorder {
        height: 8px;
        width: 60px;
        // background-color: #43cd9a;
        margin: 0 auto;
        border-radius: 16px;
        margin-top: 20px;
        opacity: 0;
      }
      .itemBorderAction {
        background-color: #43cd9a;
        opacity: 1;
      }
    }
  }
}
</style>
